<template>
  <q-dialog
    ref="dialogRef"
    position="bottom"
    square
    @hide="onDialogHide"
  >
    <q-card class="q-dialog-plugin">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section class="flex flex-center q-pt-xl q-pb-lg">
          <q-avatar
            :icon="mdiEmailHeartOutline"
            size="150px"
          />
        </q-card-section>
        <q-card-section>
          <div class="text-h6 text-center text-weight-light">
            Get our best deals delivered to your inbox!
          </div>
        </q-card-section>

        <q-card-section>
          <div class="q-gutter-y-md column">
            <q-input
              v-model="email"
              :disable="pending"
              :rules="[
                (val) => !!val || 'Email is required.',
                (val, rules) => rules.email(val) || 'Email must be valid.',
              ]"
              label="Email"
              type="email"
              clearable
            />
          </div>
        </q-card-section>

        <q-card-actions
          align="around"
          class="q-pb-md"
        >
          <q-btn
            :disable="pending"
            color="primary"
            label="Cancel"
            style="width: 100px"
            outline
            square
            @click="onDialogCancel"
          />
          <q-btn
            :loading="pending"
            color="primary"
            label="Subscribe"
            type="submit"
            style="width: 125px"
            square
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { mdiEmailHeartOutline } from '@quasar/extras/mdi-v7';
import { useDialogPluginComponent } from 'quasar';
import { LazyDialogNewsletterUnsubscribe } from '#components';

const props = defineProps({
  email: {
    type: String,
  },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogCancel } = useDialogPluginComponent();
const $q = useQuasar();

const email = ref<string | null>(props.email ?? null);

const pending = ref(false);

const onSubmit = async () => {
  pending.value = true;

  const { error } = await useFetch(() => '/api/newsletter/subscribers', {
    method: 'POST',
    body: {
      email: email.value,
    },
  });

  pending.value = false;

  if (error.value) {
    $q.notify({
      type: 'negative',
      message: error.value.data?.message ?? 'Unknown error.',
    });

    return;
  }

  $q.notify({
    type: 'positive',
    message: 'Successfully subscribed to KYGUNCO newsletter.',
    timeout: 10000,
    actions: [
      {
        label: 'Unsubscribe',
        color: 'secondary',
        handler: () => {
          onDialogHide();
          $q.dialog({
            component: LazyDialogNewsletterUnsubscribe,
            componentProps: {
              email: email.value,
            },
          });
        },
      },
      { label: 'Dismiss', color: 'white' },
    ],
  });

  onDialogHide();
};
</script>

<style lang="scss" scoped>
.q-avatar {
  color: $secondary;
  border: 1px solid $secondary;
}
</style>
